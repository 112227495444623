







































import Vue from "vue";
import { auth, OAuthProvider } from '../main'
import firebase from "firebase";


export default Vue.extend({
  name: "Fire",

  data: () => ({
    email: "",
    password: ""
  }),
  
  methods: {
    loginWithMicrosoft(){
      // const provider = new OAuthProvider('microsoft.com');
      // provider.setCustomParameters({
      //   // Force re-consent.
      //   prompt: 'consent',
      //   // Target specific email with login hint.
      //   login_hint: 'user@firstadd.onmicrosoft.com'
      // });

      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        // Force re-consent.
        // prompt: 'consent',
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: '660a30b5-8e2e-4769-b9eb-4af28bfd12bd'
        // Target specific email with login hint.
        // login_hint: 'joel.andersson@abbgymnasiet.se'
      });
      // provider.addScope('mail.read');

      firebase.auth().signInWithRedirect(provider)
      //   .then((result) => {
      //     // User is signed in.
      //     // IdP data available in result.additionalUserInfo.profile.

      //     // Get the OAuth access token and ID Token
      //     const credential = OAuthProvider.credentialFromResult(result);
      //     const accessToken = credential.accessToken;
      //     const idToken = credential.idToken;
      //   })
      //   .catch((error) => {
      //     // Handle error.
      //   });




    },
    getres(){

      auth.getRedirectResult()
      .then((result) => {
        // IdP data available in result.additionalUserInfo.profile.
        // ...

        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential as firebase.auth.OAuthCredential;

        // OAuth access and id tokens can also be retrieved:
        var accessToken = credential?.accessToken;
        var idToken = credential?.idToken;

        // console.log(accessToken, idToken)
        this.$router.push("/")
      })
      .catch((error) => {
        console.log(error)
        // Handle error.
      });
    },
    async submit(email: string, password: string){
        const user = firebase.auth().currentUser;
        auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in 
          this.$router.push("/")
          var user = userCredential.user;
          // console.log(user?.getIdToken().then(function(idToken) {
          //   console.log(idToken )
          // }))
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;

          console.log(error.message)
          // ..
        });
        // console.log(user)
     
    },
    test(){
      console.log("")
    },
  },
});
