import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Fire from '../views/Fire.vue'
import Login from '../views/login.vue'
import Printer from '../views/Printer.vue'
import Welcome from '../views/Welcome.vue'
import { firebase } from '@/main'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/printer/:serial',
    name: 'printer',
    component: Printer,
    meta: {
      requiresAuth: true
    },
    
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fire',
    name: 'Fire',
    component: Fire,
    meta: {
      requiresAuth: true
    }
    
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
    
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      requiresAuth: false
    }
    
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // console.log("Current user:" , currentUser);
  // console.log("Requires auth:" , requiresAuth);
  console.log(to)
  if (requiresAuth && !currentUser) console.log("Sending to login"), next(`/welcome`/*?to=${to.path}`*/); // logga in
  else if (!requiresAuth && currentUser) next("/"); // inloggad
  else next();
});

export default router;
