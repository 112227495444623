





















































interface PrinterInfo {
  HF: HF;
  firmware: string;
  host: string;
  online: boolean;
  protocol: string;
  serialnumber: string;
  time: string;
  name: string;
  location: string;
  type:
    | "Flashforge Finder V1"
    | "Flashforge Finder V2"
    | "FlashForge Adventurer III"
    | undefined;
}
interface HF {
  current_file: string;
  led: -1 | 0 | 1;
  progress: number;
  status: "READY" | "BUILDING_FROM_SD";
  temp: temp;
  time: number;
  camera: boolean;
  camera_image: string;
}
interface temp {
  bed_target: number;
  bed_temp: number;
  tool_target: number;
  tool_temp: number;
}
interface camera {
  image: string;
  time: number;
}
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  props: {
    printer: {
      type: Object as () => PrinterInfo,
    },
  },
  computed: {
    checkStatus: function () {
      if (this.printer.online && this.printer.HF.status == "READY") {
        return "green--text";
      } else if (
        this.printer.online &&
        this.printer.HF.status == "BUILDING_FROM_SD"
      ) {
        return "yellow--text";
      } else {
        return "red--text";
      }
    },
    PrinterImage: function () {
      if (this.printer.type == "Flashforge Finder V1") {
        return "/Images/Finder V1.jpg";
      } else if (this.printer.type == "Flashforge Finder V2") {
        return "/Images/Finder V2.jpg";
      } else if (this.printer.type == "FlashForge Adventurer III") {
        return "/Images/Adventurer.jpg";
      } else if (!this.printer.type) {
        return "/Images/UnknownPrinter.jpg";
      }
      return null;
    },
  },
  methods: {
    async refreshPrinter(serial: string) {
      await axios.get("https://api.easyprint.abbgymnasiet.se/update/" + serial);
    },
    async identifyPrinter(serial: string) {
      await axios.get(
        "https://api.easyprint.abbgymnasiet.se/identify/" + serial
      );
    },
    async toggleLed(serial: string) {
      await axios.get(
        "https://api.easyprint.abbgymnasiet.se/set_led/" + serial
      );
    },
  },
});
