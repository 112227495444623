














































import Vue from "vue";
import Printer from "../components/Printer.vue";
import db from "../main";
interface PrinterState {
  HF: HF;
  firmware: string;
  host: string;
  online: boolean;
  protocol: string;
  serialnumber: string;
  time: string;
  name: string;
  customname: string;
  location: string;
  type:
    | "FlashForge Finder V1"
    | "FlashForge Finder V2"
    | "FlashForge Adventurer III"
    | undefined;
}
interface HF {
  current_file: string;
  led: -1 | 0 | 1;
  progress: number;
  status: "READY" | "BUILDING_FROM_SD";
  temp: temp;
  time: number;
}
interface temp {
  bed_target: number;
  bed_temp: number;
  tool_target: number;
  tool_temp: number;
}
export default Vue.extend({
  name: "Home",
  components: {
    Printer,
  },
  data: () => ({
    selected: 0,
    oselected: false,
    locations: ["Any", "Spetsen", "ProdTek"],
    printers: [] as PrinterState[],
  }),
  computed: {
    location: function () {
      return this.locations[this.selected];
    },
    filterdPrinters: function () {
      console.log(this.oselected)
      let sorted = []
      if (this.selected != 0) {
        sorted = this.printers.filter((p: PrinterState) => {
          if (this.oselected == false) { 
          return p.location == this.locations[this.selected];
        }
        else {
          return p.location == this.locations[this.selected] && p.online;
        }
        
        });
      }
       else {
        if (this.oselected == false){
          sorted = this.printers;
        }
        else {
          sorted =  this.printers.filter((p: PrinterState) => {
            return p.online;
          })
        }
      }
      sorted.sort(function (a: PrinterState, b: PrinterState) {
        if (!a.online) return 1;
        if (!b.online) return -1;

        if (a.location != "Spetsen") return 1
        if (b.location != "Spetsen") return -1
        // if (a.HF.status != "READY") return 1;
        // if (b.HF.status != "READY") return -1;

        return (a.customname || a.name).localeCompare(b.customname || b.name)
      });
      return sorted;
    },
   
  },
  
  mounted() {
    const print = db.collection("printers");
    this.$bind("printers", print);
  },
  methods: {
    async getPrinter(document: string) {
      const newPrinter = await db.collection("printers").doc(document).get();
      const printerForm = newPrinter.data();
    },
  },
});
