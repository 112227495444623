






















































































import Vue from "vue";
import db, { firebase } from "../main";
import axios from "axios";


interface PrinterInfo {
  HF: HF;
  firmware: string;
  host: string;
  online: string;
  protocol: string;
  serialnumber: string;
  time: string;
  name: string;
  location: string;
  type:
    | "Flashforge Finder V1"
    | "Flashforge Finder V2"
    | "FlashForge Adventurer III"
    | undefined;
}
interface HF {
  current_file: string;
  led: -1 | 0 | 1;
  progress: number;
  status: "READY" | "BUILDING_FROM_SD";
  temp: temp;
  time: number;
  camera: boolean;
  camera_image: string;
}
interface temp {
  bed_target: number;
  bed_temp: number;
  tool_target: number;
  tool_temp: number;
}
interface camera {
  image: string;
  time: number;
}

export default Vue.extend({
  name: "PrinterPage",

  components: {},

  data: () => ({
    
    dialog: false,
    port: 0,
    success: false,
    online: "Currently Online!",
    loading: false,
    loading2: false,
    loading3: false,
    loading4: false,
    link: "",
    success2: false,
    serial: "",
    info: {} as unknown as PrinterInfo,
    // printers: [
    // ]
    cameraData: {} as unknown as camera,
  }),
  async created() {
    this.serial = this.$route.params.serial;
   
    // const print = db.collection("printers");
    // this.$bind("printers", print);

    // const info = await db.collection("printers").doc(this.serial).get();
    // const data = info.data() as PrinterInfo;
    // if (data) this.info = data;
    // this.info = data;

    const print = db.collection("printers").doc(this.serial);
    this.$bind("info", print);

    const camera = db.collection("cameraImages").doc(this.serial);
    this.$bind("cameraData", camera);
    // this.getImage(this.serial);
  },
  computed: {
    PrinterImage: function () { 
      if (this.info.type == "Flashforge Finder V1") {
        return "/Images/Finder V1.jpg";
      } else if (this.info.type == "Flashforge Finder V2") {
        return "/Images/Finder V2.jpg";
      } else if (this.info.type == "FlashForge Adventurer III") {
        return "/Images/Adventurer.jpg";
      } else if (!this.info.type) {
        return "/Images/UnknownPrinter.jpg";
      }
      return null;
    },
  },
  methods: {
    async refreshPrinter(serial: string) {
      this.loading = true;
      await axios.get("https://api.easyprint.abbgymnasiet.se/update/" + serial);
      this.loading = false;
    },
    async identifyPrinter(serial: string) {
      this.loading2 = true;
      await axios.get(
        "https://api.easyprint.abbgymnasiet.se/identify/" + serial
      );
      this.loading2 = false;
    },
    async forward(serial: string) {
      this.loading3 = true;
      let config = {}
      let currentuser = firebase.auth().currentUser
      if (currentuser){
            config = {
                headers: {
                  "Authorization": currentuser?.uid
                }}
      }
      else{
        this.success = false

        this.loading3 = false;
        return
      }
      const { data } = await axios.get(
        "https://api.easyprint.abbgymnasiet.se/forward/" + serial, config
      );
      this.success = data.success;
      if (this.success == true) {
        this.port = data.message.port;
      }
      
        
      

      this.loading3 = false;
    },
    async openCamera(serial: string){
      this.loading4 = true;
      let config = {}
      let currentuser = firebase.auth().currentUser
      if (currentuser){
            config = {
                headers: {
                  "Authorization": currentuser?.uid
                }}
      }
      else{
        this.success = false

        this.loading4 = false;
        return
      }
      const { data } = await axios.get(
        "https://api.easyprint.abbgymnasiet.se/forward/" + serial + "?camera=True", config
      );
      
      console.log("opening", "https://api.easyprint.abbgymnasiet.se:"+ data.message.port+ "/?action=stream")
      this.success2 = true;
      this.link = "http://api.easyprint.abbgymnasiet.se:"+ data.message.port+ "/?action=stream"
      window.open("http://api.easyprint.abbgymnasiet.se:"+ data.message.port+ "/?action=stream")
      
      
      
        
      

      this.loading4 = false;
    },
  },
});
