
















import Vue from "vue";

import db from "../main";
import axios from 'axios';




export default Vue.extend({
  name: "Welcome",

  data: () => ({
      data: {}
  }),
  computed: {
  },
  async mounted() {
    const res = await axios.get("https://api.easyprint.abbgymnasiet.se/status")
    // console.log(res.data)
    this.data = res.data
  },
  methods: {
    async getPrinter(document: string) {
      const newPrinter = await db.collection("test").doc(document).get();
      const printerForm = newPrinter.data();
      // console.log(printerForm);
    },
  },
});
