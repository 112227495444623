










































































import Vue from "vue";
import { auth } from "./main";
import axios from "axios";
import FullScreenFileDrop from "./components/FullScreenFileDrop.vue";
import uploadDialog from "./views/uploadDialog.vue";

export default Vue.extend({
  name: "App",
  components: {
    FullScreenFileDrop,
    uploadDialog,
  },
  data: () => ({
    //
    logined: false,
    loading: false,
    status: "red--text",
    upload: false,
    formData: undefined as unknown as FormData,
    files: undefined as unknown as File,
  }),
  methods: {
    onDrop(formData: FormData, files: File[]) {
      console.log(formData);
      console.log(files);
      this.formData = formData;
      this.files = files[0];
      this.upload = true;
    },
    async checkStatus() {
      try {
        let stat = await axios.get(
          "https://api.easyprint.abbgymnasiet.se/status/"
        );
        this.$store.state.status = {
          spets: stat.data.spets,
          prod: stat.data.prod,
        }
        if (stat.data.nodes > 0) {
          this.status = "green--text";
        } else {
          this.status = "yellow--text";
        }
      } catch (error) {
        this.status = "red--text";
      }
    },
    logout() {
      auth
        .signOut()
        .then(() => {
          this.$router.push("login");
          // Sign-out successful.
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });
    },
  },
  async mounted() {
    this.checkStatus();
    setInterval(this.checkStatus, 10000);
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let a = this;
    auth.onAuthStateChanged(function (user) {
      a.logined = false;
      a.loading = false;
      if (user) {
        // User is signed in.
        a.logined = true;
      }
    });
  },
  // sockets: {
  //   connect() {
  //     console.log('socket connected')
  //   },
  //   lobby(val) {
  //     console.log(val)
  //   }
  // },
});
