import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


import { firestorePlugin } from 'vuefire'
import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { CombinedVueInstance } from 'vue/types/vue'
// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';

// const socket = io('https://api.easyprint.abbgymnasiet.se', {
//   path:"/ws/socket.io"
// });

// Vue.use(VueSocketIOExt, socket);


Vue.use(firestorePlugin)

  const firebaseConfig = {
    apiKey: "AIzaSyBETK2E88FNM0_xQjIEPFjIhPGUI3I4yco",
    authDomain: "easyprint-ezp.firebaseapp.com",
    projectId: "easyprint-ezp",
    storageBucket: "easyprint-ezp.appspot.com",
    messagingSenderId: "788531489909",
    appId: "1:788531489909:web:d5f1ac62fad1a393f2dc3c"
  };

  firebase.initializeApp(firebaseConfig);


const functions = firebase.functions();
const db = firebase.firestore()
const auth = firebase.auth()
const OAuthProvider = firebase.auth.OAuthProvider


if (location.hostname === "localhost") {
  // auth.useEmulator('http://localhost:9099/');
  // db.useEmulator("localhost", 5002);
  functions.useEmulator("localhost", 5001)}

export default db;
export { firebase, functions, auth, OAuthProvider };


let app = undefined as unknown
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      components: { App },
      render: h => h(App)
    }).$mount('#app')
  }
})
