import Vue from 'vue'
import Vuex, { Commit } from 'vuex'
import { firebase } from "../main"
import createPersistedState from "vuex-persistedstate"; //Denna gör så att vue inte tappar värden när du uppdaterar.

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    status: {
      spets: false,
      prod: false
    }
  },
  getters: {
    user: state => state.user,
    spets: state => state.status.spets,
    prod: state => state.status.prod,
  },
  mutations: {
    // SET_STATUS(state: any, spets: boolean, prod: boolean) {
    //   state.status.spets = spets
    //   state.status.prod = prod
    // },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user)
    },
    // setStatus({ commit }: {commit: Commit}, spets: boolean, prod: boolean) {
    //   commit("SET_STATUS", spets, prod)
    // },
    logoutUser({ commit }) {
      return firebase
        .auth()
        .signOut()
        .then(() => {
          commit("SET_USER", null);
        });
    }
  },  
  modules: {
  }
})
