



































import Vue from "vue";

import db from "../main";
import PrinterListItem from "../components/PrinterListItem.vue";

interface PrinterState {
  HF: HF;
  firmware: string;
  host: string;
  online: string;
  protocol: string;
  serialnumber: string;
  time: string;
  name: string;
  customname: string;
  location: string;
  type:
    | "FlashForge Finder V1"
    | "FlashForge Finder V2"
    | "FlashForge Adventurer III"
    | undefined;
}
interface HF {
  current_file: string;
  led: -1 | 0 | 1;
  progress: number;
  status: "READY" | "BUILDING_FROM_SD";
  temp: temp;
  time: number;
}
interface temp {
  bed_target: number;
  bed_temp: number;
  tool_target: number;
  tool_temp: number;
}

export default Vue.extend({
  name: "UploadDialog",
  props: {
    formData: {
      type: FormData,
      required: false,
    },
    files: {
      type: File,
      required: false,
    },
  },
  components: {
    PrinterListItem,
  },

  data: () => ({
    printers: [] as PrinterState[],
    img: "",
  }),
  computed: {
    sortedPrinters: function () {
      let sorted = [...this.printers];
      sorted.sort(function (a: PrinterState, b: PrinterState) {
        if (!a.online) return 1;
        if (!b.online) return -1;

        if (a.HF.status != "READY") return 1;
        if (b.HF.status != "READY") return -1;

        return b.HF.progress * 100 - a.HF.progress * 100;
      });
      return sorted;
    },
  },
  created() {
    const print = db.collection("printers");
    this.$bind("printers", print);

    if (this.formData) {
      this.uploadFile();
    }
  },
  methods: {
    t() {
      console.log(this.sortedPrinters);
      console.log(this.printers);
      this.$forceUpdate();
    },
    async uploadFile() {
      // this.files = this.$refs.file.files[0];
      let formelement = this.$refs.file as HTMLInputElement;
      if (this.formData) {
        formelement = this.formData as unknown as HTMLInputElement;
      }

      if (formelement.files) {
        console.log(formelement.files[0]);
        let img = await this.blobToArrayBuffer(formelement.files[0]);
        if (img instanceof ArrayBuffer)
          this.img = this._arrayBufferToBase64(img.slice(58, 58 + 14454));
      }
    },
    // submitFile() {

    // },
    async blobToArrayBuffer(blob: Blob): Promise<string | ArrayBuffer | null> {
      if ("arrayBuffer" in blob) return await blob.arrayBuffer();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsArrayBuffer(blob);
      });
    },
    _arrayBufferToBase64(buffer: ArrayBuffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
  },
});
