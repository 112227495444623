






































import Vue from "vue";
import moment from "moment";
interface PrinterState {
  HF: HF;
  firmware: string;
  host: string;
  online: string;
  protocol: string;
  serialnumber: string;
  time: string;
  name: string;
  customname: string;
  location: string;
  type:
    | "FlashForge Finder V1"
    | "FlashForge Finder V2"
    | "FlashForge Adventurer III"
    | undefined;
}
interface HF {
  current_file: string;
  led: -1 | 0 | 1;
  progress: number;
  status: "READY" | "BUILDING_FROM_SD";
  temp: temp;
  time: number;
}
interface temp {
  bed_target: number;
  bed_temp: number;
  tool_target: number;
  tool_temp: number;
}
export default Vue.extend({
  name: "Printer",
  props: {
    data: {
      
      type: Object as () => PrinterState,
    },
  },
  data: ()=> ({
    time: "",
    newDateTime: 0
  }),
  mounted() {
    // console.log(this.data)
    this.newDateTime = new Date().getTime();
    this.time = moment(this.data.HF.time * 1000).fromNow();
    setInterval(() => {
      this.newDateTime = new Date().getTime();
      this.time = moment(this.data.HF.time * 1000).fromNow();
    }, 10000);
  },
  created() {
    // console.log("ahhhhh", this.data.HF)
  },
});
