















import Vue from "vue";
import db from "../main";

interface PrinterState {
  printerName: string;
  currentPrint: string;
  printingStatus: number;
  bedTemp: number;
  toolTemp: number;
  timeLeft: number;
}

export default Vue.extend({
  name: "Fire",

  data: () => ({
    Printer3 : "Printer3",
    printers: [
      // {
      //   printerName: "CoolPrinter",
      //   currentPrint: "Hållare.fx",
      //   printingStatus: 69,
      //   bedTemp: 100,
      //   toolTemp: 200,
      //   timeLeft: 500
      // },
      // {
      //   printerName: "CoolarePrinter",
      //   currentPrint: "Print.fx",
      //   printingStatus: 420,
      //   bedTemp: 20,
      //   toolTemp: 300,
      //   timeLeft: 500
      // },
    ],
  }),
  mounted() {
    const print = db.collection("test")
    this.$bind("printers", print);

  },
  methods: {
    test(){

      this.printers.forEach((printer: PrinterState) => {
        // console.log(printer)
      })
    },
    async getPrinter(document: string){
      const newPrinter = await db
        .collection("test")
        .doc(document)
        .get()
      const printerForm= newPrinter.data() 
       
      // console.log(printerForm)
      // if (printerForm){
      //   this.printers.push({
      //     printerName: printerForm.printerName,
      //     currentPrint: printerForm.currentPrint,
      //     printingStatus: printerForm.printingStatus,
      //     bedTemp: printerForm.bedTemp,
      //     toolTemp: printerForm.toolTemp,
      //     timeLeft: printerForm.timeLeft
      //     })
      // }
    }
  },
});
